@import "../../common/shared";

.lics {
	background: mat-color($mat-orange, 400);
	padding-bottom: 5rem;

	.text-color{
		color: mat-color($mat-deep-orange, 900);
	}
}


