@import "../../common/shared";

.clients {
	//background: mat-color($mat-gray, 300);
	padding-bottom: 3rem;
}

.cont {
	overflow-y: hidden;
	overflow-x: auto;
}

.client-strip{
	display: flex;
	flex-direction: row;
	//align-content: stretch;
	align-items: center;
	//justify-content: stretch;

	.client-logo{
		width: 200px;
		margin: 1rem;
		filter: grayscale(100%);
		&:hover{
			filter: none;
		}
	}

	.logo-scania{
		//clip:rect(5px 5px 48px 100px);
	}
}

