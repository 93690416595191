﻿@import "bs";
@import "mat-colors";

$nav-heigth: 48px;
$enable-shadows:true;
$enable-gradients:true;
$images: "/images";


//mixins
@mixin rounded-corners($radius: 5px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin gay-shadow($startColor: rgba(0, 0, 0, 0.8), $endColor: rgba(255, 255, 255, 0.8)) {
	-moz-box-shadow: 0 1px 2px $startColor inset, 0 1px 1px $endColor;
	-webkit-box-shadow: 0 1px 2px $startColor inset, 0 1px 1px $endColor;
	box-shadow: 0 1px 2px $startColor inset, 0 1px 1px $endColor;
}

@mixin gay-shadow2($startColor: rgba(0, 0, 0, 0.7), $endColor: rgba(255, 255, 255, 0.3)) {
	-moz-box-shadow: 0 1px 1px $startColor inset;
	-webkit-box-shadow: 0 1px 1px $startColor inset;
	box-shadow: 0 1px 1px $startColor inset;
	border-bottom: 1px solid $endColor;
}

@mixin gay-button($bg: rgba(0, 0, 0, 0.4), $radius: 25px) {
	/*
	background: none repeat scroll 0 0 rgba(255, 255, 255, 0.15);
	*/
	background: none repeat scroll 0 0 $bg;
	@include rounded-corners($radius);
	@include gay-shadow2();
	display: block;
	padding: 3px 15px 1px 15px;
	margin-top: 7px;
	text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

@mixin gm-text-shadow-heavy() {
	text-shadow: 0 4px 3px rgba(0, 0, 0, 0.4),
	0 8px 13px rgba(0, 0, 0, 0.1),
	0 18px 23px rgba(0, 0, 0, 0.1);
}

@mixin gm-text-shadow-seriously3d() {
	text-shadow: 0 1px 0 #ccc,
	0 2px 0 #c9c9c9,
	0 3px 0 #bbb,
	0 4px 0 #b9b9b9,
	0 5px 0 #aaa,
	0 6px 1px rgba(0, 0, 0, .1),
	0 0 5px rgba(0, 0, 0, .1),
	0 1px 3px rgba(0, 0, 0, .3),
	0 3px 5px rgba(0, 0, 0, .2),
	0 5px 10px rgba(0, 0, 0, .25),
	0 10px 10px rgba(0, 0, 0, .2),
	0 20px 20px rgba(0, 0, 0, .15);
}

@mixin gm-text-shadow-inset() {
	text-shadow: 0 1px 0 #ccc,
	0 2px 0 #c9c9c9,
	0 3px 0 #bbb,
	0 4px 0 #b9b9b9,
	0 5px 0 #aaa,
	0 6px 1px rgba(0, 0, 0, .1),
	0 0 5px rgba(0, 0, 0, .1),
	0 1px 3px rgba(0, 0, 0, .3),
	0 3px 5px rgba(0, 0, 0, .2),
	0 5px 10px rgba(0, 0, 0, .25),
	0 10px 10px rgba(0, 0, 0, .2),
	0 20px 20px rgba(0, 0, 0, .15);
}

@mixin translatez() {
	-webkit-transform: translatez(0);
	-moz-transform: translatez(0);
	-ms-transform: translatez(0);
	-o-transform: translatez(0);
	transform: translatez(0);
}

@mixin gm-gradient-horizontal($startColor: #555, $endColor: #333) {
	background-color: $endColor;
	background-image: -moz-linear-gradient(left, $startColor, $endColor); // FF 3.6+
	background-image: -ms-linear-gradient(left, $startColor, $endColor); // IE10
	background-image: -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image: -webkit-linear-gradient(left, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image: -o-linear-gradient(left, $startColor, $endColor); // Opera 11.10
	background-image: linear-gradient(left, $startColor, $endColor); // Le standard
	background-repeat: repeat-x;
}

@mixin gm-gradient-vertical($startColor: #555, $endColor: #333) {
	background-color: transparent;
	background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
	background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
	background-image: linear-gradient(top, $startColor, $endColor); // The standard
	background-repeat: repeat-x;
}

@mixin gm-gradient-directional($startColor: #555, $endColor: #333, $deg: 45deg) {
	background-color: $endColor;
	background-repeat: repeat-x;
	background-image: -moz-linear-gradient($deg, $startColor, $endColor); // FF 3.6+
	background-image: -ms-linear-gradient($deg, $startColor, $endColor); // IE10
	background-image: -webkit-linear-gradient($deg, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image: -o-linear-gradient($deg, $startColor, $endColor); // Opera 11.10
	background-image: linear-gradient($deg, $startColor, $endColor); // The standard
}

@mixin gm-gradient-vertical-three-colors($startColor: #00b3ee, $midColor: #7a43b6, $colorStop: 50%, $endColor: #c3325f) {
	background-color: mix($midColor, $endColor, 80%);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), color-stop($colorStop, $midColor), to($endColor));
	background-image: -webkit-linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-image: -moz-linear-gradient(top, $startColor, $midColor $colorStop, $endColor);
	background-image: -ms-linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-image: -o-linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-image: linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-repeat: no-repeat;
}

@mixin gm-gradient-vertical3($startColor: #00b3ee, $midColor: #7a43b6, $endColor: #c3325f) {
	background-image: -webkit-linear-gradient($startColor, $midColor, $endColor);
	background-image: -moz-linear-gradient(top, $startColor, $midColor, $endColor);
	background-image: -ms-linear-gradient($startColor, $midColor, $endColor);
	background-image: -o-linear-gradient($startColor, $midColor, $endColor);
	background-image: linear-gradient($startColor, $midColor, $endColor);
	background-repeat: no-repeat;
}

@mixin gm-gradient-radial($innerColor: #555, $outerColor: #333) {
	background-color: $outerColor;
	background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($innerColor), to($outerColor));
	background-image: -webkit-radial-gradient(circle, $innerColor, $outerColor);
	background-image: -moz-radial-gradient(circle, $innerColor, $outerColor);
	background-image: -ms-radial-gradient(circle, $innerColor, $outerColor);
	background-image: -o-radial-gradient(circle, $innerColor, $outerColor);
	background-repeat: no-repeat;
}

@mixin gm-gradient-striped($color, $angle: -45deg) {
	@include gradient-striped($color, $angle);
}

@mixin gm-gradient-gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
	background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

@mixin desat() {
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	filter: grayscale(100%);
	//Firefox 3.5+, IE10
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

@mixin sat() {
	filter: none;
	-webkit-filter: grayscale(0%);
}

@mixin blur() {
	filter: blur(3px);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-o-filter: blur(3px);
	-ms-filter: blur(3px);
}

@mixin focus() {
	filter: none;
	-webkit-filter: blur(0px);
	-moz-filter: blur(0px);
	-ms-filter: blur(0px);
	filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
}

@mixin easeinDesat() {
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease; /* Fade to color for Chrome and Safari */
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden; /* Fix for transition flickering */
}

@mixin gm-transition($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}



