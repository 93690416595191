@import "../shared";

.card {
	min-width: 200px
}
.media {
	min-height: 140px;
	filter: grayscale(100%);
	&:hover{
		filter: none;
	}
}
