@import "../common/shared";

body {
	//margin: 0;
	//padding: 0;
	font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	font-size: 0.95rem;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

button:focus {
	//outline: 1px dotted;
	//outline: 5px auto -webkit-focus-ring-color;
	outline: none;
}

.sect {
	margin-top: -$nav-heigth;
	padding-top: $nav-heigth;
}

.gm-wrapper {
	min-height: 100vh;
	padding-top: 0px;
	position: relative;
	padding-bottom: 247px;

	.marketing {
		text-align: center;
	}

	//pages
	//.jumbotron.first img {
	//	width: 80%;
	//	margin: 4px auto 0;
	//}

	section {
		@extend .sect;
		//padding-bottom: 3rem;
	}
}
.gm-jump {
	animation: bounce .3s ease infinite alternate;
	text-shadow:
		0 1px 0 #CCC,
		0 2px 0 #CCC,
		0 3px 0 #CCC,
		0 4px 0 #CCC,
		0 5px 0 #CCC,
		0 6px 0 transparent,
		0 7px 0 transparent,
		0 8px 0 transparent,
		0 9px 0 transparent,
		0 10px 10px rgba(0, 0, 0, .6);
}
.gay-button2 {
	box-shadow: inset 0 2px 2px #fff, 0 0 0 1px #bbb, 0 1px 1px #aaa;
}
.gm-icon-shadow {
	//-webkit-text-shadow: rgba(0, 0, 0, 0.5) 1px -1px 3px;
	//text-shadow: rgba(0, 0, 0, 0.5) 1px -1px 3px;
	//-webkit-text-stroke: 0.6px;
	//text-shadow: 0 1px #FFFFFF, 3px 1px 4px rgba(0, 0, 0, 0.5);
	//text-shadow: 0 1px #FFFFFF, 3px 1px 4px rgba(0, 0, 0, 0.5);
	text-shadow: 3px 1px 4px rgba(0, 0, 0, 0.5);
}
.gm-tiny-text {
	font-size: 9px;
	text-shadow: none;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Oswald', sans-serif;
}

//h4{font-family: 'Roboto', sans-serif;}

.text-color { color: #D26C22; }
.primary-font { font-family: 'Oswald', sans-serif; }



