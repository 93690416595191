.gm-flag  {
	background-image: url(/flags.png);
	width: 16px;
	height: 11px;
	display: inline-block;
	overflow: hidden;
	//margin: 2px 7px 2px 7px;
	margin: 0px 7px 2px 7px;
	line-height: 27px;
	vertical-align: middle;
}
.gm-flag-ad {background-position: -16px 0 !important} /*B1 - all flags checked on 26/02/2013*/
.gm-flag-ae {background-position: -32px 0 !important} /*C1*/
.gm-flag-af {background-position: -48px 0 !important} /*D1*/
.gm-flag-ag {background-position: -64px 0 !important} /*E1*/
.gm-flag-ai {background-position: -80px 0 !important} /*F1*/
.gm-flag-al {background-position: -96px 0 !important} /*G1*/
.gm-flag-am {background-position: -112px 0 !important} /*H1*/
.gm-flag-an {background-position: -128px 0 !important} /*I1*/
.gm-flag-ao {background-position: -144px 0 !important} /*J1*/
.gm-flag-ar {background-position: -160px 0 !important} /*K1*/
.gm-flag-as {background-position: -176px 0 !important} /*L1*/
.gm-flag-at {background-position: -192px 0 !important} /*M1*/
.gm-flag-au {background-position: -208px 0 !important} /*N1*/
.gm-flag-aw {background-position: -224px 0 !important} /*O1*/
.gm-flag-az {background-position: -240px 0 !important} /*P1*/
.gm-flag-ba {background-position: 0 -11px !important} /*A2*/
.gm-flag-bb {background-position: -16px -11px !important} /*B2*/
.gm-flag-bd {background-position: -32px -11px !important} /*C2*/
.gm-flag-be {background-position: -48px -11px !important} /*D2*/
.gm-flag-bf {background-position: -64px -11px !important} /*E2*/
.gm-flag-bg {background-position: -80px -11px !important} /*F2*/
.gm-flag-bh {background-position: -96px -11px !important} /*G2*/
.gm-flag-bi {background-position: -112px -11px !important} /*H2*/
.gm-flag-bj {background-position: -128px -11px !important} /*I2*/
.gm-flag-bm {background-position: -144px -11px !important} /*J2*/
.gm-flag-bn {background-position: -160px -11px !important} /*K2*/
.gm-flag-bo {background-position: -176px -11px !important} /*L2*/
.gm-flag-br {background-position: -192px -11px !important} /*M2*/
.gm-flag-bs {background-position: -208px -11px !important} /*N2*/
.gm-flag-bt {background-position: -224px -11px !important} /*O2*/
.gm-flag-bv {background-position: -240px -11px !important} /*P2*/
.gm-flag-bw {background-position: 0 -22px !important} /*A3*/
.gm-flag-by {background-position: -16px -22px !important} /*B3*/
.gm-flag-bz {background-position: -32px -22px !important} /*C3*/
.gm-flag-ca {background-position: -48px -22px !important} /*D3*/
.gm-flag-ct {background-position: -64px -22px !important} /*E3*/
.gm-flag-cd {background-position: -80px -22px !important} /*F3*/
.gm-flag-cf {background-position: -96px -22px !important} /*G3*/
.gm-flag-cg {background-position: -112px -22px !important} /*H3*/
.gm-flag-ch {background-position: -128px -22px !important} /*I3*/
.gm-flag-ci {background-position: -144px -22px !important} /*J3*/
.gm-flag-ck {background-position: -160px -22px !important} /*K3*/
.gm-flag-cl {background-position: -176px -22px !important} /*L3*/
.gm-flag-cm {background-position: -192px -22px !important} /*M3*/
.gm-flag-cn {background-position: -208px -22px !important} /*N3*/
.gm-flag-co {background-position: -224px -22px !important} /*O3*/
.gm-flag-cr {background-position: -240px -22px !important} /*P3*/
.gm-flag-cu {background-position: 0 -33px !important} /*A4*/
.gm-flag-cv {background-position: -16px -33px !important} /*B4*/
.gm-flag-cy {background-position: -32px -33px !important} /*C4*/
.gm-flag-cz {background-position: -48px -33px !important} /*D4*/
.gm-flag-de {background-position: -64px -33px !important} /*E4*/
.gm-flag-dj {background-position: -80px -33px !important} /*F4*/
.gm-flag-dk {background-position: -96px -33px !important} /*G4*/
.gm-flag-dm {background-position: -112px -33px !important} /*H4*/
.gm-flag-do {background-position: -128px -33px !important} /*I4*/
.gm-flag-dz {background-position: -144px -33px !important} /*J4*/
.gm-flag-ec {background-position: -160px -33px !important} /*K4*/
.gm-flag-ee {background-position: -176px -33px !important} /*L4*/
.gm-flag-eg {background-position: -192px -33px !important} /*M4*/
.gm-flag-eh {background-position: -208px -33px !important} /*N4*/
/* O4 NOT USED? (England) */
.gm-flag-er {background-position: -240px -33px !important} /*P4*/
.gm-flag-es {background-position: 0 -44px !important} /*A5*/
.gm-flag-et {background-position: -16px -44px !important} /*B5*/
.gm-flag-eu {background-position: -32px -44px !important} /*C5*/
.gm-flag-fi {background-position: -48px -44px !important} /*D5*/
.gm-flag-fj {background-position: -64px -44px !important} /*E5*/
.gm-flag-fk {background-position: -80px -44px !important} /*F5*/
.gm-flag-fm {background-position: -96px -44px !important} /*G5*/
.gm-flag-fo {background-position: -112px -44px !important} /*H5*/
.gm-flag-fr {background-position: -128px -44px !important} /*I5*/
.gm-flag-ga {background-position: -144px -44px !important} /*J5*/
.gm-flag-gb {background-position: -160px -44px !important} /*K5*/
.gm-flag-gd {background-position: -176px -44px !important} /*L5*/
.gm-flag-ge {background-position: -192px -44px !important} /*M5*/
.gm-flag-gf {background-position: -208px -44px !important} /*N5*/
.gm-flag-gh {background-position: -224px -44px !important} /*O5*/
.gm-flag-gi {background-position: -240px -44px !important} /*P5*/
.gm-flag-gl {background-position: 0 -55px !important} /*A6*/
.gm-flag-gm {background-position: -16px -55px !important} /*B6*/
.gm-flag-gn {background-position: -32px -55px !important} /*C6*/
.gm-flag-gp {background-position: -48px -55px !important} /*D6*/
.gm-flag-gq {background-position: -64px -55px !important} /*E6*/
.gm-flag-gr {background-position: -80px -55px !important} /*F6*/
.gm-flag-gs {background-position: -96px -55px !important} /*G6*/
.gm-flag-gt {background-position: -112px -55px !important} /*H6*/
.gm-flag-gu {background-position: -128px -55px !important} /*I6*/
.gm-flag-gw {background-position: -144px -55px !important} /*J6*/
.gm-flag-gy {background-position: -160px -55px !important} /*K6*/
.gm-flag-hk {background-position: -176px -55px !important} /*L6*/
.gm-flag-hm {background-position: -192px -55px !important} /*M6*/
.gm-flag-hn {background-position: -208px -55px !important} /*N6*/
.gm-flag-hr {background-position: -224px -55px !important} /*O6*/
.gm-flag-ht {background-position: -240px -55px !important} /*P6*/
.gm-flag-hu {background-position: 0 -66px !important} /*A7*/
.gm-flag-id {background-position: -16px -66px !important} /*B7*/
.gm-flag-ie {background-position: -32px -66px !important} /*C7*/
.gm-flag-il {background-position: -48px -66px !important} /*D7*/
.gm-flag-in {background-position: -64px -66px !important} /*E7*/
.gm-flag-io {background-position: -80px -66px !important} /*F7*/
.gm-flag-iq {background-position: -96px -66px !important} /*G7*/
.gm-flag-ir {background-position: -112px -66px !important} /*H7*/
.gm-flag-is {background-position: -128px -66px !important} /*I7*/
.gm-flag-it {background-position: -144px -66px !important} /*J7*/
.gm-flag-jm {background-position: -160px -66px !important} /*K7*/
.gm-flag-jo {background-position: -176px -66px !important} /*L7*/
.gm-flag-jp {background-position: -192px -66px !important} /*M7*/
.gm-flag-ke {background-position: -208px -66px !important} /*N7*/
.gm-flag-kg {background-position: -224px -66px !important} /*O7*/
.gm-flag-kh {background-position: -240px -66px !important} /*P7*/
.gm-flag-ki {background-position: 0 -77px !important} /*A8*/
.gm-flag-km {background-position: -16px -77px !important} /*B8*/
.gm-flag-kn {background-position: -32px -77px !important} /*C8*/
.gm-flag-kp {background-position: -48px -77px !important} /*D8*/
.gm-flag-kr {background-position: -64px -77px !important} /*E8*/
.gm-flag-kw {background-position: -80px -77px !important} /*F8*/
.gm-flag-ky {background-position: -96px -77px !important} /*G8*/
.gm-flag-kz {background-position: -112px -77px !important} /*H8*/
.gm-flag-la {background-position: -128px -77px !important} /*I8*/
.gm-flag-lb {background-position: -144px -77px !important} /*J8*/
.gm-flag-lc {background-position: -160px -77px !important} /*K8*/
.gm-flag-li {background-position: -176px -77px !important} /*L8*/
.gm-flag-lk {background-position: -192px -77px !important} /*M8*/
.gm-flag-lr {background-position: -208px -77px !important} /*N8*/
.gm-flag-ls {background-position: -224px -77px !important} /*O8*/
.gm-flag-lt {background-position: -240px -77px !important} /*P8*/
.gm-flag-lu {background-position: 0 -88px !important} /*A9*/
.gm-flag-lv {background-position: -16px -88px !important} /*B9*/
.gm-flag-ly {background-position: -32px -88px !important} /*C9*/
.gm-flag-ma {background-position: -48px -88px !important} /*D9*/
.gm-flag-mc {background-position: -64px -88px !important} /*E9*/
.gm-flag-md {background-position: -80px -88px !important} /*F9*/
.gm-flag-me {background-position: -96px -88px !important} /*G9*/
.gm-flag-mg {background-position: -112px -88px !important} /*H9*/
.gm-flag-mh {background-position: -128px -88px !important} /*I9*/
.gm-flag-mk {background-position: -144px -88px !important} /*J9*/
.gm-flag-ml {background-position: -160px -88px !important} /*K9*/
.gm-flag-mm {background-position: -176px -88px !important} /*L9*/
.gm-flag-mn {background-position: -192px -88px !important} /*M9*/
.gm-flag-mo {background-position: -208px -88px !important} /*N9*/
.gm-flag-mp {background-position: -224px -88px !important} /*O9*/
.gm-flag-mq {background-position: -240px -88px !important} /*P9*/
.gm-flag-mr {background-position: 0 -99px !important} /*A10*/
.gm-flag-ms {background-position: -16px -99px !important} /*B10*/
.gm-flag-mt {background-position: -32px -99px !important} /*C10*/
.gm-flag-mu {background-position: -48px -99px !important} /*D10*/
.gm-flag-mv {background-position: -64px -99px !important} /*E10*/
.gm-flag-mw {background-position: -80px -99px !important} /*F10*/
.gm-flag-mx {background-position: -96px -99px !important} /*G10*/
.gm-flag-my {background-position: -112px -99px !important} /*H10*/
.gm-flag-mz {background-position: -128px -99px !important} /*I10*/
.gm-flag-na {background-position: -144px -99px !important} /*J10*/
.gm-flag-nc {background-position: -160px -99px !important} /*K10*/
.gm-flag-ne {background-position: -176px -99px !important} /*L10*/
.gm-flag-nf {background-position: -192px -99px !important} /*M10*/
.gm-flag-ng {background-position: -208px -99px !important} /*N10*/
.gm-flag-ni {background-position: -224px -99px !important} /*O10*/
.gm-flag-nl {background-position: -240px -99px !important} /*P10*/
.gm-flag-no {background-position: 0 -110px !important} /*A11*/
.gm-flag-np {background-position: -16px -110px !important} /*B11*/
.gm-flag-nr {background-position: -32px -110px !important} /*C11*/
.gm-flag-nu {background-position: -48px -110px !important} /*D11*/
.gm-flag-nz {background-position: -64px -110px !important} /*E11*/
.gm-flag-om {background-position: -80px -110px !important} /*F11*/
.gm-flag-pa {background-position: -96px -110px !important} /*G11*/
.gm-flag-pe {background-position: -112px -110px !important} /*H11*/
.gm-flag-pf {background-position: -128px -110px !important} /*I11*/
.gm-flag-pg {background-position: -144px -110px !important} /*J11*/
.gm-flag-ph {background-position: -160px -110px !important} /*K11*/
.gm-flag-pk {background-position: -176px -110px !important} /*L11*/
.gm-flag-pl {background-position: -192px -110px !important} /*M11*/
.gm-flag-pm {background-position: -208px -110px !important} /*N11*/
.gm-flag-pn {background-position: -224px -110px !important} /*O11*/
.gm-flag-pr {background-position: -240px -110px !important} /*P11*/
.gm-flag-ps {background-position: 0 -121px !important} /*A12*/
.gm-flag-pt {background-position: -16px -121px !important} /*B12*/
.gm-flag-pw {background-position: -32px -121px !important} /*C12*/
.gm-flag-py {background-position: -48px -121px !important} /*D12*/
.gm-flag-qa {background-position: -64px -121px !important} /*E12*/
.gm-flag-re {background-position: -80px -121px !important} /*F12*/
.gm-flag-ro {background-position: -96px -121px !important} /*G12*/
.gm-flag-rs {background-position: -112px -121px !important} /*H12*/
.gm-flag-ru {background-position: -128px -121px !important} /*I12*/
.gm-flag-rw {background-position: -144px -121px !important} /*J12*/
.gm-flag-sa {background-position: -160px -121px !important} /*K12*/
.gm-flag-sb {background-position: -176px -121px !important} /*L12*/
.gm-flag-sc {background-position: -192px -121px !important} /*M12*/
/* N12 NOT USED? (Scotland) */
.gm-flag-sd {background-position: -224px -121px !important} /*O12*/
.gm-flag-se {background-position: -240px -121px !important} /*P12*/
.gm-flag-sg {background-position: 0 -132px !important} /*A13*/
.gm-flag-sh {background-position: -16px -132px !important} /*B13*/
.gm-flag-si {background-position: -32px -132px !important} /*C13*/
.gm-flag-sk {background-position: -48px -132px !important} /*D13*/
.gm-flag-sl {background-position: -64px -132px !important} /*E13*/
.gm-flag-sm {background-position: -80px -132px !important} /*F13*/
.gm-flag-sn {background-position: -96px -132px !important} /*G13*/
.gm-flag-so {background-position: -112px -132px !important} /*H13*/
.gm-flag-sr {background-position: -128px -132px !important} /*I13*/
.gm-flag-st {background-position: -144px -132px !important} /*J13*/
.gm-flag-sv {background-position: -160px -132px !important} /*K13*/
.gm-flag-sy {background-position: -176px -132px !important} /*L13*/
.gm-flag-sz {background-position: -192px -132px !important} /*M13*/
.gm-flag-tc {background-position: -208px -132px !important} /*N13*/
.gm-flag-td {background-position: -224px -132px !important} /*O13*/
.gm-flag-tf {background-position: -240px -132px !important} /*P13*/
.gm-flag-tg {background-position: 0 -143px !important} /*A14*/
.gm-flag-th {background-position: -16px -143px !important} /*B14*/
.gm-flag-tj {background-position: -32px -143px !important} /*C14*/
.gm-flag-tk {background-position: -48px -143px !important} /*D14*/
.gm-flag-tl {background-position: -64px -143px !important} /*E14*/
.gm-flag-tm {background-position: -80px -143px !important} /*F14*/
.gm-flag-tn {background-position: -96px -143px !important} /*G14*/
.gm-flag-to {background-position: -112px -143px !important} /*H14*/
.gm-flag-tr {background-position: -128px -143px !important} /*I14*/
.gm-flag-tt {background-position: -144px -143px !important} /*J14*/
.gm-flag-tv {background-position: -160px -143px !important} /*K14*/
.gm-flag-tw {background-position: -176px -143px !important} /*L14*/
.gm-flag-tz {background-position: -192px -143px !important} /*M14*/
.gm-flag-ua {background-position: -208px -143px !important} /*N14*/
.gm-flag-ug {background-position: -224px -143px !important} /*O14*/
.gm-flag-um {background-position: -240px -143px !important} /*P14*/
.gm-flag-us {background-position: 0 -154px !important} /*A15*/
.gm-flag-uy {background-position: -16px -154px !important} /*B15*/
.gm-flag-uz {background-position: -32px -154px !important} /*C15*/
.gm-flag-va {background-position: -48px -154px !important} /*D15*/
.gm-flag-vc {background-position: -64px -154px !important} /*E15*/
.gm-flag-ve {background-position: -80px -154px !important} /*F15*/
.gm-flag-vg {background-position: -96px -154px !important} /*G15*/
.gm-flag-vi {background-position: -112px -154px !important} /*H15*/
.gm-flag-vn {background-position: -128px -154px !important} /*I15*/
.gm-flag-vu {background-position: -144px -154px !important} /*J15*/
/* K15 NOT USED? (Wales) */
.gm-flag-wf {background-position: -176px -154px !important} /*L15*/
.gm-flag-ws {background-position: -192px -154px !important} /*M15*/
.gm-flag-ye {background-position: -208px -154px !important} /*N15*/
.gm-flag-yt {background-position: -224px -154px !important} /*O15*/
.gm-flag-za {background-position: -240px -154px !important} /*P15*/
.gm-flag-zm {background-position: 0 -165px !important} /*A16*/
.gm-flag-zw {background-position: -16px -165px !important} /*B16*/
