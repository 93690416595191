$enable-prefers-reduced-motion-media-query: false;
@import '~bootstrap/scss/bootstrap';

@import 'common/fuck-animate-reduced-motion';
@import 'common/anim';
//@import 'common/44ihover';
//@import 'common/buttons';
@import 'sections/sections';


