@import "../../common/shared";

.intro {
	//background: mat-color($mat-light-green, 700);
	color: #fff;
	position: relative;
	overflow: hidden;
	padding-bottom: 2.5rem;

	.text-color {
		color: mat-color($mat-orange, 100);
	}

	p{
		background-color: rgba(0, 0, 0, 0.5) !important;
		padding: 0.5rem;
	}

	.headline {
		small {
			color: mat-color($mat-grey, 200);
		}
		.border-color {
			border-color: mat-color($mat-orange, 200);
		}
	}

	.cover{
	  //background: linear-gradient(to top,rgba(107,156,219,0.1) 25%, rgba(107,156,219,1) 100%);
	  background: mat-color($mat-light-green, 800);
	  opacity: 0.7;
	  position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.video-background {
		position: absolute;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.8;
		pointer-events: none;

		@include media-breakpoint-down(lg) {
			.embed-responsive{width: 110%;}
		}
		@include media-breakpoint-down(md) {
			.embed-responsive{width: 170%;}
		}
		@include media-breakpoint-down(sm) {
			.embed-responsive{width: 300%;}
		}
		@include media-breakpoint-down(xs) {
			.embed-responsive{width: 700%;}
		}
	}
}
