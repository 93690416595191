@import "../../common/shared";

.company{
	//background: mat-color($mat-light-green, 600);
	//color: #fff;
	padding-bottom: 2rem;
}

.punane {
	background: red;
}

.services-item {
	i {
		float: left;
		margin: 0px 15px 0 0;
		transition: all 500ms;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
		color: mat-color($mat-orange, 700);
	}
}


