@import "../common/bs";

@import "gm-langbar";
@import "gm-navbar";
@import "flags";

.a-style{
	text-decoration: none;
	color: inherit;
}

.tool-bar{
	display: flex;
	flex-grow: 1;
	//align-self: stretch;
	justify-content: space-between;
	align-items: center;
	min-height: 75px;


	@include media-breakpoint-down(lg) {
		max-width: 1050px;
		flex-wrap: nowrap;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0px;
		padding-left: 5px;
	}
	.navbar-nav{
		align-self: stretch;
		align-content: stretch;
		flex-direction: row;

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.navbar-toggler {
		display: none;
		border: none;
		padding: 0;
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}
	a{
		@extend .a-style
	}
}

.menu-item{
	a{
		@extend .a-style
	}
}
