﻿$logo-size-min: 250px;

.gm-navbar {
	//font-family: 'Oswald', sans-serif;
	font-family: 'Roboto', 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
	font-size: 14px;
	min-height: 75px;
	margin: 0;
	border: 0;
	padding: 0 15px;

	@include box-shadow(0 0 16px 0 rgba(50, 50, 50, 0.5));
	//box-shadow: 0 0 16px 0 rgba(50, 50, 50, 0.5);

	@include gradient-y(#eee, #fff);

	@include media-breakpoint-down(sm) {
		padding: 0 5px;
	}

	.container{
		align-self: stretch;
		justify-content: space-between;
		@include media-breakpoint-down(lg) {
			max-width: 1050px;
			flex-wrap: nowrap;
		}
		@include media-breakpoint-down(sm) {
			padding-right: 0px;
			padding-left: 0px;
		}
		.navbar-nav{
			align-self: stretch;
			//align-items: center;
			align-content: stretch;
			@include media-breakpoint-down(lg) {
				text-align: center;
			}
			@include media-breakpoint-down(sm) {
				display: none;
			}
			li {
				display: flex;
				align-items: center;
				//align-content: stretch;
				background: none repeat scroll 0% 0% transparent;
				@include transition(background 500ms);
				padding: 1px 3px;

				.nav-link{
					text-transform: uppercase;
					color: #111;
				}
				&:hover {
					background-color: #D26C22 !important;
					i {
						color: #fff
					}
					.nav-link{
						color: #fff;
					}
				}
				&.dropdown.show{
					background-color: #D26C22 !important;
					i {
						color: #fff
					}
					.nav-link{
						color: #fff;
					}
					&:after {
						content: "\f0d8";
						font-family: FontAwesome;
						position: absolute;
						z-index: 9999;
						bottom: -27px;
						left: 50%;
						margin-left: -10px;
						font-size: 44px;
						color: #fff;
					}
					.dropdown-menu {
						font-size: 14px;
						border-top: 1px solid #bbb;
						top: 97%;
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						a.nav-link {
							color: #000;
							padding: 7px 20px;
							&:hover, &:focus {
								border-left: 4px solid #848484;
								padding-left: 16px;
							}
							i {
								color: #BDBDBD
							}
						}
					}
				}
			}
		}
		.navbar-toggler {
			display: none;
			border: none;
			padding: 0;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}

	i {
		color: #BDBDBD
	}
}

