.fancy{
	background: rgba(255,255,255, 0.7);
}

.lic-card {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;

	//background: rgba(100,100,100, 0.8);

	.content {
		display: flex;
		flex-direction: column;
		//flex: 1 0 auto;
		flex:2;
	}
	.ico{
		//border-radius: 2px 0 0 2px;
		display: flex;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		width: 80px;
		flex-shrink: 0;
		text-align: center;
		font-size: 45px;
		background: rgba(0, 0, 0, 0.2);
	}
	.img{

	}
}
