@import "../common/bs";

.sidebar{
	width: 300px;

	.header{
		background: #aaaaaa;
		min-height: 100px;
		position: relative;
	}


	.middle {
		//transition: .5s ease;
		//opacity: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;

		//background: rgba(0,0,0,0.6);
		@include gradient-y(rgba(0,0,0,0.3), rgba(0,0,0,0.7));


		//linear-gradient(90deg, rgba(0, 0, 0, .25), rgba(0, 0, 0, .001));
		//transform: translate(-50%, -50%);
		//-ms-transform: translate(-50%, -50%);
		//text-align: center;
	}

	a{
		color: inherit;
		text-decoration: none;
	}
}
