@import "../common/shared";

$action-button: #C1631F;

.nothing-fancy {

}

.main-slideshow {
	position: relative;
	margin-top: 114px;
	@include media-breakpoint-down(sm) {
		margin-top: 75px;
	}
}

.slide-img{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.jumbotron.first {
	@extend .slide-img;
	background-image: url('#{$images}/slaid_1-1.jpg');
	background-position: left;
}
.jumbotron.second {
	@extend .slide-img;
	background-image: url('#{$images}/slaid_6-1.jpg');
}
.jumbotron.third {
	@extend .slide-img;
	background-image: url('#{$images}/slaid_7.jpg');
}
.jumbotron._4 {
	@extend .slide-img;
	background-image: url('#{$images}/panorama5.jpg');
}
.jumbotron._5 {
	@extend .slide-img;
	background-image: url('#{$images}/panorama3.jpg');
}
.jumbotron {
	width: 100%;
	height: 470px;
	margin-bottom: 20px;
	padding-top: 30px;
	padding-bottom: 30px;
	color: #FFF;
	border-bottom: 1px solid #eee;
	overflow: hidden;
	border-radius: 0;

	h1.orange{
		i{
			background: rgba(193, 99, 31, 0.7);
		}
	}

	h1 {
		font-size: 36px;
		line-height: 1.5;
		margin-bottom: 20px;
		text-shadow: 1px 1px 5px rgba(0,0,0,0.3);

		//background-color: rgba(210, 108, 34, 0.5)
		i{
			font-style: normal;
			//background: rgba(193, 99, 31, 0.7);
			background: rgba(237, 62, 73, 0.7);
			padding: 0 10px;

			display: inline-block;
			margin-bottom: 3px;
			//padding: 3px 15px;

			color: white;
			@include box-shadow(0 0 10px rgba(0, 0, 0, 0.175));
		}

	}
	p{
		font-family: 'Oswald', sans-serif;
		i {
			background-color: rgba(0, 0, 0, 0.6);
			padding: 0 10px;
			font-style: normal;
		}
	}

	gm-paddy{margin-top:20px;}
}


.jumbotron.first img {
	width: 90%;
	margin: 50px auto 0;
}
.jumbotron.second img {
	position: absolute;
	bottom: -600px;
	left: 50%;
	margin-left: -300px;
	cursor: pointer;
	box-shadow: 0 0 15px rgba(0,0,0,0.8);
	transition: bottom 0.3s;
	-webkit-transition: bottom 0.3s;
}
.jumbotron.second img:hover {
	bottom: -590px;
}
.jumbotron.third .video {
	width: 80%;
	margin: 50px auto 0 auto;
}
.jumbotron.first h1,
.jumbotron.third h1 {
	margin-top: 50px;
}
@media (max-width: 768px) { /* Adjusting for mobile */
	.jumbotron.first h1,
	.jumbotron.third h1 {
		margin-top: 20px;
	}
}
.jumbotron .lead {
	text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
}
.jumbotron .btn {
	//font-size: 18px;
	padding: 15px 30px;
	margin-top: 10px;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
}

/* Slideshow controls */

/*a.slideshow-arrow  {
	position: absolute;
	display: block;
	height: 100px;
	width: 50px;
	top: 50%;
	margin-top: -60px;
	background: transparent;
	color: transparent;
	font-size: 32px;
	font-weight: 300;
	line-height: 100px;
	text-align: center;
	cursor: pointer;
    -webkit-transition: background 500ms, color 500ms;
            transition: background 500ms, color 500ms;
}
.main-slideshow:hover a.slideshow-arrow  {
	background: rgba(0, 0, 0, 0.5);
	color: #FFF;
}
a.slideshow-arrow:hover,
a.slideshow-arrow:active {
	text-decoration: none;
}
a.slideshow-arrow-prev {
	left: 0;
	right: auto;
	padding-right: 5px;
}
a.slideshow-arrow-next {
	right: 0;
	left: auto;
	padding-left: 5px;
}*/

a.slideshow-arrow {
	height: 50px;
	width: 50px;
	border-radius: 50px;
	line-height: 52px;
	text-align: center;
	position: absolute;
	display: block;
	top: 50%;
	margin-top: -25px;
	background: transparent;
	color: transparent;

	//background: rgba(0, 0, 0, 1);
	//color: #FFF;

	font-size: 32px;
	//font-weight: 300;
	cursor: pointer;
	//-webkit-transition: background 300ms, color 300ms;
	//-o-transition: background 300ms, color 300ms;
	transition: background 300ms, color 300ms;
}
.carousel:hover a.slideshow-arrow {
	background: rgba(0, 0, 0, 0.5);
	color: #FFF;
}
.carousel:hover a.slideshow-arrow:hover,
.carousel:hover a.slideshow-arrow:active {
	background: rgba(0, 0, 0, 0.7);
	text-decoration: none;
}
a.slideshow-arrow-prev {
	left: 10px;
	right: auto;
	padding-right: 5px;
}
a.slideshow-arrow-next {
	right: 10px;
	left: auto;
	padding-left: 5px;
}
