﻿@import "../common/shared";
@import "../common/variables-colors";

.card-row {
	display: flex;
	flex-flow: row wrap;
	align-content: stretch;
	align-items: stretch;

	justify-content: stretch;

	.card {
		margin: 0px 3px 5px 3px;
		flex: 1;
	}
	.card-img-top{
		height: 12rem;
		filter: grayscale(100%);
	}
}



//.sect {
//	margin-top: -$menu-heigth;
//	padding-top: $menu-heigth;
//}
//
//.backpadd {
//	padding-top: 27px;
//	padding-bottom: 27px;
//}
//
//.gm-wrapper {
//	section.intro {
//		@extend .sect;
//		background: $gray-lighter;
//		padding-bottom: 30px;
//		min-height: 300px;
//	}
//
//	section.company {
//		@extend .sect;
//
//		.back {
//			@extend .backpadd;
//		}
//	}
//
//	section.products {
//		@extend .sect;
//
//		.back {
//			@extend .backpadd;
//		}
//	}
//
//	section.apps {
//		@extend .sect;
//
//		.back {
//			@extend .backpadd;
//			background: $gray-lighter;
//		}
//	}
//
//	section.news {
//		@extend .sect;
//		color: #fff;
//
//		.back {
//			@extend .backpadd;
//			background-color: #b39ddb;
//			//background-color: #ea7373;
//		}
//
//		h2 > span {
//			color: lighten(map-get($brown, 'lighten-3'), 40%);
//			border-color: lighten(map-get($brown, 'lighten-3'), 20%);
//		}
//	}
//
//	section.contact {
//		padding-top: 27px;
//		padding-bottom: 27px;
//		//background-color: #579bbe;
//		background-color: rgba(154, 195, 216, 0.6);
//
//		@media (max-width: $screen-sm) {
//			padding-top: 0;
//		}
//
//		#enq_message > h1 {
//			margin-bottom: 40px;
//		}
//
//		form label {
//			margin-bottom: 5px;
//		}
//
//		form button {
//			color: #fff
//		}
//
//		.blue_bg_60 {
//			background-color: rgba(154, 195, 216, 0.6);
//		}
//
//		.blue_bg {
//			//background-color: #579bbe;
//			background-color: rgb(154, 195, 216);
//		}
//
//		.padded-cell {
//			padding: 20px;
//		}
//	}
//
//	section.clients {
//		@extend .sect;
//
//		.back {
//			@extend .backpadd;
//		}
//	}
//}
