﻿.gm-langbar {
	@include gradient-y(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.8));
	border: 0;
	font-family: 'Oswald', sans-serif;
	//text-shadow: 0 -1px 0 rgba(0, 0, 0, .15);
	border-radius: 0;
	margin-bottom: 0;
	width: 100%;
	min-height: 20px;
	font-size: 0.9em;
	position: relative;
	padding: 0;
	color: #ecf0f1;

	@include media-breakpoint-down(sm) {
		display: none;
	}

	.navbar-nav > li > a {
		color: #777;
		display: inline-block;
		transition: background 500ms;
		line-height: 38px;
		padding: 0px 10px 2px 10px;
		&:hover {
			text-decoration: none;
			background: #242424;
		}
	}

	.gm-navbar-languagelabel2 {
		margin-right: 7px;
		padding-top: 4px;
		padding-bottom: 2px;
	}
	.gm-navbar-languagelabel {
		margin-right: 7px;
		padding: 4px 3px 2px;
	}

	.dropdown-header {
		display: block;
		padding: 3px 20px;
		//font-size: 14px;
		line-height: 1.42857143;
		color: #777;
		white-space: nowrap;
	}
	.dropdown-menu {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin: 0;
		float: left;
		min-width: 160px;
		padding: 5px 0;
		//font-size: 14px;
		text-align: left;
		list-style: none;
		background-color: #fff;
		background-clip: padding-box;
		box-shadow: 0 6px 12px rgba(0,0,0,.175);
		border-top: none;

		li > a.nav-link {
			display: block;
			padding: 4px 0 7px 20px;
			color: #262626;
			&:hover, &:focus{
				border-left: 4px solid #848484;
				padding-left: 16px;
				text-decoration: none;
				background-color: #f5f5f5;
			}
		}
	}
}
