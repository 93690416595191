@import "../common/shared";

.headline {
	margin-top: 2rem;
	padding-bottom: 1px;
	border-bottom: 2px dotted #eee;
	margin-bottom: 30px;
	line-height: 50px;

	.border-color {
		padding-bottom: 2px;
		border-bottom-width: 2px;
		border-bottom-style: solid;
		border-color: #D26C22;
	}

	small {
		font-size: 65%;
		line-height: 1;
		color: #777;
		margin-left: 0.5rem;
	}
}


