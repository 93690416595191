@import 'shared';

.gm-parallax-container {
	height: 300px;
	position: relative;
	overflow: hidden;
	.gm-navig-buttons{
		position: absolute;
		bottom: 10px;
		background-color: rgba(60%,60%,60%,0.6);
		height: 26px;
		left:30%;
		right:30%;
		z-index: 1001;
	}
	.gm-navig-buttons-centre{
		position:relative;
		margin: 0 auto;
		width: 105px;
	}
	input {
		//position: absolute;
		//bottom: 15px;
		//left: 50%;
		width: 9px;
		height: 9px;
		z-index: 1001;
		cursor: pointer;
		-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		filter: alpha(opacity=0);
		opacity: 0.6;
	}
	input + label {
		//position: absolute;
		//bottom: 15px;
		//left: 50%;
		width: 10px;
		height: 10px;
		display: block;
		z-index: 1000;
		border: 3px solid #fff;
		border: 3px solid rgba(255,255,255,0.9);
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		-webkit-transition: background-color linear 0.1s;
		-moz-transition: background-color linear 0.1s;
		-o-transition: background-color linear 0.1s;
		-ms-transition: background-color linear 0.1s;
		transition: background-color linear 0.1s;
	}
	input:checked + label {
		background-color: #fff;
		background-color: rgba(255,255,255,0.9);
	}
	/*.sp-selector-1, .button-label-1 {
		margin-left: -36px;
	}
	.sp-selector-2, .button-label-2 {
		margin-left: -18px;
	}
	.sp-selector-4, .button-label-4 {
		margin-left: 18px;
	}
	.sp-selector-5, .button-label-5 {
		margin-left: 36px;
	}*/
}

input.sp-selector-1:checked ~ .gm-parallax-bg {
	left: 0;
}

input.sp-selector-2:checked ~ .gm-parallax-bg {
	left: -100%;
}

input.sp-selector-3:checked ~ .gm-parallax-bg {
	left: -200%;
}

input.sp-selector-4:checked ~ .gm-parallax-bg {
	left: -300%;
}

input.sp-selector-5:checked ~ .gm-parallax-bg {
	left: -400%;
}

.gm-parallax-bg {
	height: 50%;
	left: 0;
	bottom: 0;
	position: absolute;
	width: 500%;
	//background-color: rgba(20%,20%,20%,0.6);
	height: 50%;
	@include gm-transition((left ease-in 0.5s, opacity ease-in 0.5s));

	.gm-bg-item{
		width: 20%;
		float:left;
	}
}
.gm-slider {
	position: relative;
	left: 0;
	width: 500%;
	height: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
	@include gm-transition((left ease-in 0.5s, opacity ease-in 0.4s));
}

.delay-1 {
	animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
}
.delay-2 {
	animation-delay: 0.6s;
	-webkit-animation-delay: 0.6s;
}
.delay-3 {
	animation-delay: 1s;
	-webkit-animation-delay: 1s;
}
.delay-4 {
	-webkit-animation-delay: 1200ms;
	animation-delay: 1200ms;
}
.delay-5 {
	-webkit-animation-delay: 1500ms;
	animation-delay: 1500ms;
}
.delay-6 {
	-webkit-animation-delay: 1800ms;
	animation-delay: 1800ms;
}
.delay-7 {
	-webkit-animation-delay: 2100ms;
	animation-delay: 2100ms;
}
.delay-8 {
	-webkit-animation-delay: 2400ms;
	animation-delay: 2400ms;
}
